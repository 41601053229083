<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Tin tức </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10">
        <div class="form-group">
          <router-link to="/admin/news/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới Tin tức
          </router-link>
        </div>
      </div>

      <div>
        <form method="post" @submit="mySearch" id="my_search">
          <div class="row">
            <div class="col-sm-4 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
               
                autocomplete="off"
                placeholder="Nhập tên..."

                v-model="formSearch.name"
              />

              <button class="btn btn-sm btn-warning mt-3" type="submit">
                <i class="fa fa-search-plus"></i> TÌM KIẾM
              </button>
            </div>
             <div class="col-sm-4 mb-3" >
                          <select
                            class="form-control"
                            name="cate"
                            v-model="formSearch.cate"
                          >
                            <option value="">Danh mục</option>
                            <template v-for="elm in mycate">
                              <option
                                :value="elm.id"
                                :key="elm.id"
                                v-if="elm.parentId == null"
                              >
                                {{ elm.name }}
                              </option>

                             
                            </template>
                          </select>
              
            </div>
            <div class="col-sm-4 mb-3" >
                          <select
                            class="form-control"
                            name="cate"
                            v-model="formSearch.department"
                          >
                            <option value="">Phòng ban</option>
                            <template v-for="elm in department">
                              <option
                                :value="elm.id"
                                :key="elm.id"
                               
                              >
                                {{ elm.name }}
                              </option>

                             
                            </template>
                          </select>
              
            </div>
          </div>
        </form>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Tin tức  : ( {{ page_size }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class=" table table-sm table-striped table-responsive">
                  <thead class="bg-primary text-light thead-dark">
                    <tr>
                       <th>STT</th>
                        <th>
                          <button @click="removeAll" type="button" class="btn btn-sm btn-danger"> 
                              <i class="fa fa-trash"></i>
                           </button>
                        </th>
                      <th @click="orderByName()">
                         
                            Tên Tin tức 
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.name=='ASC'}"></i>
                          
                      </th>
                      <th @click="orderByDert()">

                        
                             Phòng ban
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.dert=='ASC'}"></i>
                            
                        


                      </th>

                      <th  @click="orderByUser()">

                        
                            User
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.user=='ASC'}"></i>
                            
                        


                      </th>
                      <th @click="orderByCreated()">
                       
                            Ngày tạo
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.created=='ASC'}"></i>
                            
                        

                        </th>
                      <th  @click="orderByUpdated()">
                      
                            Ngày sửa
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.updated=='ASC'}"></i>
                            
                       

       </th>
                      <th  @click="orderByParent()">

                       
                            Danh mục
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.parent=='ASC'}"></i>
                            
                         

                      </th>

                    
                       <th @click="orderByCate()">


                       
                           Danh mục con
                            <i  class="fas fa-angle-down" :class="{'fa-angle-up':orderby.cate=='ASC'}"></i>
                        
                          
                           </th>
                      <th>Tuỳ chọn</th>
                    </tr>
                  </thead>


                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td v-for="(x,y) in 6" :key="y">
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(value, key) in mydata"
                      :Key="key"
                    >
                      <td>{{key+1+(page*20-20)}}</td>
                      <td>
                         <input v-if="checkRole(value)" type="checkbox" :value="value.id" v-model="is_remove" />
                      </td>
                      <td style="width: 400px;">
                        <a
                          :href="'/vn/ben-xe-mien-dong/' + value.slug"
                          class="text text-primary"
                          target="_black"
                        >
                          {{ value.name }}
                        </a>
                        <br />
                     
                        <template v-if="value.picture !== null">
                          <img
                            :src="my_url + '' + value.picture"
                            :title="value.picture"
                            style="width: 100px;"
                            class="img-thumbnail"
                          />
                        </template>
                      </td>
                        <td >
                        {{ value.departmentId | View_Department(department) }}
                      </td>
                      <td>
                          {{value.userCreate}}
                      </td>
                      <td v-if="value.createdTime!=undefined">
                          {{value.createdTime.substring(0,10) }}
                      </td>
                      <td v-if="value.dateCreate!=undefined">
                          {{value.dateCreate.substring(0,10)}}
                      </td>
                      <td v-else> 
                             {{value.modifiedTime.substring(0,10)}}
                      </td>
                     
                      <td >
                        {{ value.parentId | ViewCate(mycate) }}
                      </td>
                       <td >
                        {{ value.cateId | ViewCate(mycate) }}
                      </td>


                      <td v-if="role==1 ||  checkRole(value)">
                        <router-link
                          class="btn btn-sm btn-success"
                          v-bind:to="'/admin/news/edit/' + value.id"
                        >
                          <i class="fa fa-edit"></i>
                          Sửa
                        </router-link>
                        <div
                          class="btn btn-sm btn-danger"
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-trash"></i> Xoá
                        </div>

                        <p> {{value.SubmitDate}}</p>
                      </td>

                      <td v-else>
                        <p class="text-danger">Không có quyền Truy cập </p>
                         <div
                          v-if="language=='en'"
                          class="btn btn-sm btn-danger click_remove"
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-trash"></i> Xoá
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <nav v-if="mydata != null && mydata.length > 1">
                  <VuePagination
                    :page-count="Math.ceil(page_size/20)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";
export default {
  name: "News.List",
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      department:null,
      main:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:"",
          department:""
      },
      orderby:{
        name:"ASC",
        cate:"ASC",
        parent:"ASC",
        user:"ASC",
        created:"ASC",
        updated:"ASC",
        dert:"ASC"
      },
      language: window.my_header.language,
      role:2,
      MyRole:null,
      params:{
                  "column":"modifiedTime",
                  "sort":"DESC"
              },
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },
  filters:{
    ViewCate:function(id_cate,mycate){
        var result= mycate.filter( (n)=> n.id==id_cate);
        if(result.length){
            return result[0].name;
        }
        return "";


    },
    View_Department:function(id_dert,mydertment){
        var result= mydertment.filter( (n)=> n.id==id_dert);
        if(result.length){
            return result[0].name;
        }
        return "";


    }

  },
  created(){  
      let isLogin= this.$session.get("isLogin");
      this.isLogin=isLogin.permistions.split(",");

      this.MyRole=this.$session.get("Role");

      let Customer= this.$session.get("Customer");

      this.role=Customer.isRole;


           let p1= axios
              .get(window.my_api + "api/newscate/get-newscates", {
                headers: window.my_header
              })
              .then((res) => {
                if (res.status == 200) {
                  this.mycate = res.data.data;
                  
                } else {
                  alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                }
              });


           let p2=  axios
              .get(window.my_api + "api/news/get-cms-news?page=1",

                  {
                      params:this.params,
                      headers: window.my_header
                    }
              )
              .then((res) => {
                if (res.status == 200) {
                  this.mydata = this.main = res.data.data.records;
                  this.page_size=res.data.data.total;
                  this.mydata.sort((a, b) => a.orderBy - b.orderBy);
                  
                } else {
                  alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                }
              });


           let p3= axios
                .get(window.my_api + "api/department/get-departments", {
                  headers: window.my_header,
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.department = res.data.data;
                    
                  }
                })
                .catch((err) => {
                  console.log(err);
                });

      Promise.all([p1,p2,p3])
             .then( ()=>{
            
                this.is_loading = false;
             });
  },
  methods: {
    checkRole(value){
        if(value.departmentId!=null && value.departmentId!=undefined){
            if(value.departmentId!=this.MyRole[0].departmentId){
                return false;
            }
        }
       return this.isLogin.indexOf(value.parentId)>-1
      
    },
    orderByName(){

        if(this.orderby.name=="ASC"){
            this.orderby.name="DESC";
        }else{
          this.orderby.name="ASC";
        }
       
         this.page = 1;
         this.params={
                  "column":"name",
                  "sort":this.orderby.name
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"name",
                  "sort":this.orderby.name
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },
    orderByParent(){
        if(this.orderby.parent=="ASC"){
            this.orderby.parent="DESC";
        }else{
          this.orderby.parent="ASC";
        }
        
         this.page = 1;
           this.params={
                   "column":"parentId",
                  "sort":this.orderby.parent
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"parentId",
                  "sort":this.orderby.parent
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },
    orderByCate(){
        if(this.orderby.cate=="ASC"){
            this.orderby.cate="DESC";
        }else{
          this.orderby.cate="ASC";
        }


          this.page = 1;
          this.params={
                  "column":"cateId",
                  "sort":this.orderby.cate
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"cateId",
                  "sort":this.orderby.cate
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },

    orderByUser(){
        if(this.orderby.user=="ASC"){
            this.orderby.user="DESC";
        }else{
          this.orderby.user="ASC";
        }


          this.page = 1;
          this.params={
                 "column":"userCreate",
                  "sort":this.orderby.user
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"userCreate",
                  "sort":this.orderby.user
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },
    orderByDert(){
        if(this.orderby.dert=="ASC"){
            this.orderby.dert="DESC";
        }else{
          this.orderby.dert="ASC";
        }


          this.page = 1;
          this.params={
                "column":"departmentid",
                  "sort":this.orderby.dert
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"departmentid",
                  "sort":this.orderby.dert
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },
   
     orderByCreated(){
        if(this.orderby.created=="ASC"){
            this.orderby.created="DESC";
        }else{
          this.orderby.created="ASC";
        }


          this.page = 1;
          this.params={
                "column":"createdTime",
                  "sort":this.orderby.created
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"createdTime",
                  "sort":this.orderby.created
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },

    orderByUpdated(){
        if(this.orderby.updated=="ASC"){
            this.orderby.updated="DESC";
        }else{
          this.orderby.updated="ASC";
        }


          this.page = 1;
           this.params={
                  "column":"modifiedTime",
                  "sort":this.orderby.updated
              };
          axios.get(window.my_api + "api/news/get-cms-news?page="+1, 
            {
              params:{
                  "column":"modifiedTime",
                  "sort":this.orderby.updated
              },
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.mydata = res.data.data.records;
              
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
    },




    changePage(e) {
      this.page = e;
      axios.get(window.my_api + "api/news/get-cms-news?page="+e, 
        {
          params:this.params,
          headers: window.my_header
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.mydata = res.data.data.records;
          
          
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });
      
    },
    paginationslice(data, page) {
     return   axios.get(window.my_api + "api/news/get-cms-news?page="+page,
          {
              headers: window.my_header
          }
      )
      .then((res) => {
        if (res.status == 200) {
          data = res.data.data.records;
          
          return data;
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });


      // return data.filter((value, n) => {
      //   return n >= pagesize * (page - 1) && n < pagesize * page;
      // });



    },
    mySearch(event) {
      event.preventDefault();
       this.page = 1;
       console.log(this.formSearch);
      let  p={
              page:this.page,
              keyword:this.formSearch.name,
              catetegory:this.formSearch.cate,
              departments:this.formSearch.department

            };
       let params ={...this.params,...p};

       console.log("BT",params);
      axios
        .get(window.my_api + "api/news/get-cms-news",{
            headers: window.my_header,
            params:params

        })
        .then((res) => {
          if (res.status == 200) {
            this.mydata = this.main = res.data.data.records;
            this.page_size=res.data.data.total;
            this.mydata.sort((a, b) => a.orderBy - b.orderBy);
             
          } else {
            alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
          }
          this.is_loading = false;
        });
      return false;
        
      // var form =new FormData(document.getElementById("my_search") ) ;

      // this.mydata=this.main.filter( (n)=>{
      //       if(form.get("name").length && form.get("cate").length){
      //         return   (n.cateId == form.get("cate") || n.parentId== form.get("cate")) && n.name.search(form.get("name") ) > -1;
      //       }
      //       if(form.get("name").length){
      //         return    n.name.search(form.get("name") ) > -1;
      //       }
      //       if(form.get("cate").length){
      //         return   (n.cateId == form.get("cate") || n.parentId== form.get("cate")) ;
      //       }
      //       return n;


      // });

      // return false;
      // axios
      //   .get(window.my_api + "api/news/get-cms-news?Search=" + this.search)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       this.mydata = res.data.data;
      //       this.page = 1;
      //     } else {
      //       alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
      //     }
      //     this.is_loading = false;
      //   });
    },
    removeAll(){
        
        if(this.is_remove.length > 0){
           for (var i = this.is_remove.length - 1; i >= 0; i--) {
            
              this.removeElement(this.is_remove[i]);
            }
        }
        return false;
         
    },
    removeElement(id) {
    if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/news/remove-news", {
                data: { newsIds: [id] },
                headers: window.my_header
              })
              
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");
                  this.mydata = this.mydata.filter((el) => el.id !== id);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        
    },
  },
};
</script>








